import { sesStore } from "./BrowserStore.ts";

const getGlobal = () => {
  return (typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : {});
};

const konsole = (function(win) {
  const noop = () => {};
  return win?.console || Object.fromEntries([
    "debug",
    "error",
    "info",
    "log",
    "warn",
    "dir",
    "dirxml",
    "table",
    "trace",
    "group",
    "groupCollapsed",
    "groupEnd",
    "clear",
    "count",
    "countReset",
    "assert",
    "profile",
    "profileEnd",
    "time",
    "timeLog",
    "timeEnd",
    "timeStamp",
    "context",
    "memory"
  ].map(v => [v, noop]));
})(getGlobal());

const logOnce = (function() {
  const logged = {};
  return (msg, ...args) => {
    if (logged[msg]) { return; }
    logged[msg] = true;
    konsole.warn(msg, ...args);
  };
})();


function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}


const isExternalUrl = (function() {
  const rx_url = /^([^:/?#]+:)?(a)?([^?#]+)?(\?[^#]*)?(#.*)?/;
  const rx_hasProt = /^https?:\/\//i;

  return (url) => {
    if (!url) {
      return false;
    }

    if (url.search(rx_hasProt) > -1) {
      return true;
    }
    var match = (url || "").match(rx_url);
    if (
      typeof match[1] === "string" && match[1].length > 0 &&
      match[1].toLowerCase() !== getGlobal().location.protocol
    ) {
      return true;
    }

    if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(
      new RegExp(":(" + {"http:": 80, "https:": 443}[getGlobal().location.protocol] + ")?$"),
      ""
    ) !== getGlobal().location.host) {
      return true;
    }
    return false;
  };
})();



const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;



const debounce = (callback, delay = 250) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      timeoutId = null;
      callback(...args);
    }, delay);
  };
};


function throttle(func, wait = 100) {
  let timer = null;
  return function(...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  };
}



const parseParams = (function() {
  return (locSearch, fn) => {
    locSearch = (locSearch || window.location.search);
    if (!locSearch) return {};

    locSearch = locSearch.replace(/&+/g, "&").replace(/\?+/g, "?").replace(/&\?/g, "&");

    const value = locSearch.substr(1).split('&');
    const params = {};
    for (let i = 0; i < value.length; ++i) {
      const p = value[i].split('=', 2);
      if (p.length === 1) {
        params[p[0]] = "";
      }
      else {
        const v = decodeURIComponent(p[1].replace(/\+/g, " "));
        params[p[0]] = typeof fn === "function" ? fn(v) : v;
      }
    }

    return params;
  };
})();

const encodeParams = (obj) => {
  let str = "";
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key) && key) {
      if (str !== "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }
  }
  return str ? `?${str}` : '';
};



const notEmpty = (v) => (
  typeof v !== "undefined" &&
  v !== null &&
  v.length > 0
);


const fixHashFormat = (function() {
  const charMap = [
    ['a', /[áăặằǎâậäǟȧǡạȁàȃāąåḁãɐ]/ig],
    ['e', /[éĕěȩḝêệḙëėẹȅèȇēęẽɛɘǝ]/ig],
    ['i', /[íĭǐîïịȉìȋīįɨĩ]/ig],
    ['o', /[óŏǒôộöȫȯȱọőȍòơớợờỡȏⱺōǫǭøǿõṍȭ]/ig],
    ['u', /[úŭǔûṷüǘǚǜǖṳụűȕùưứựừữȗūųůũṹṵ]/ig],
    ['-', /[\s\n.,§'"+!%/=()?:_~ˇ^˘°˛`˙´˝÷×¤ß;*<>@&<>[]{}]/g],
    ['', /#/g]
  ];
  return (s) => {
    if (!s) return s;
    s = (s + '').toLowerCase();
    for (let i = 0; i < charMap.length; i++) {
      const [to, from] = charMap[i];
      s = s.replace(from, to);
    }
    return s;
  };
})();


const filterInput = (function() {
  const escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;'
  };
  const rx_others = /javascript:|data:|https?:|mailto:/ig;

  const rx = /[&<>'"]/g;
  const mapFn = c => escape[c];
  return (s, remove) => (s + '').replace(rx_others, '').replace(rx, remove ? '' : mapFn);
})();

const getLocation = () => getGlobal().location || {};
const getLocHash = () => (getLocation().hash || '').replace('#', '');
const getLocPath = () => (getLocation().pathname || '');
// const getLocSearch = () => (getLocation().search || '');





const isCssSelectorSupported = (function() {
  const cache = {};

  return (selector) => {
    if (typeof cache[selector] === "boolean") {
      return cache[selector];
    }

    const style = document.createElement('style');
    document.head.appendChild(style);

    let supported = true;

    try {
      style.sheet.insertRule(selector + '{}', 0);
    }
    catch (e) {
      supported = false;
    }
    finally {
      document.head.removeChild(style);
    }

    return (cache[selector] = supported);
  };
})();


const firstBool = (arr, defBool) => {
  if (typeof arr === "boolean") {
    return arr;
  }
  else if (Array.isArray(arr)) {
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === "boolean") {
        return arr[i];
      }
    }
  }
  return !!defBool;
};


const locReload = (function() {
  let timeoutId = null;

  return (urlOnce = window.location.href, fallbackUrl) => {
    if (sesStore.get('locReloadId') !== urlOnce) {
      sesStore.set('locReloadId', urlOnce);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
    else if (!timeoutId) {
      console.warn('Cancel reload loop');
      timeoutId = setTimeout(() => {
        timeoutId = null;
        sesStore.remove('locReloadId');
        if (fallbackUrl) {
          window.location.href = fallbackUrl;
        }
      }, 1500);
    }
  };
})();


const meta = {
  setTitle: (title) => {
    document.title = process.env.RAZZLE_INITIAL_PAGE_TITLE + (title ? ' - ' + title : '');
  }
};


const getAccurateDate = (function() {
  const offset = 0; // TODO: implement server time
  return () => {
    var date = new Date();
    date.setTime(date.getTime() + offset);
    return date;
  };
})();



const firstLetterToUpperCase = (text) => (
  text.charAt(0).toUpperCase() + text.slice(1)
);

export {
  firstLetterToUpperCase,
  logOnce,
  getAccurateDate,
  getGlobal,
  meta,
  locReload,
  firstBool,
  isCssSelectorSupported,
  debounce,
  throttle,
  isNumeric,
  getLocation,
  getLocHash,
  getLocPath,
  // getLocSearch,
  isExternalUrl,
  parseParams,
  encodeParams,
  getWindowWidth,
  fixHashFormat,
  notEmpty,
  konsole,
  filterInput
};
