import {DEV_MODE, PREVIEW_MODE} from '../controller/devel';
import { konsole, isExternalUrl } from './util';

type TypeSetup = {
  debug?: boolean;
  presetName?: string;
  excludedTags?: string[];
  wildcardSelectors?: string[];
  convertLineBrakes?: boolean;
  allowedTags?: any[];
  fixBadLineBrakes?: boolean;
  removeLastBr?: boolean;
  rangyCompatibility?: boolean;
  rangyMarkerSelector?: string;
  allowedAttrs?: any[];
  excludedAttrs?: any[];
  removeEvents?: boolean;
  removeExternalAction?: boolean;
  removeJsFromHrefs?: boolean;
  textRestoreMethod?: 'visible' | 'all';
}

interface TypeCache {
  [key: string] : string;
}

type HTMLTag = HTMLElement & HTMLAnchorElement & HTMLFormElement;

export const filterHtml = (function() {
  const tidyCache: TypeCache = {};
  const rxJs = /^javascript:/i;
  const rxLb = /(?:\r\n|\r|\n)/g;
  const rxBadLb = /\\n/g;
  const rxLastBr = /\s+?<br\s+?\/?>\s+?$/i;

  function tidyWrapper(html: string, setup: TypeSetup) {
    const cacheId: string = JSON.stringify(setup) + html;

    if (tidyCache[cacheId]) {
      return tidyCache[cacheId];
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const filtered = tidy(html, setup, cacheId, setup);

    // console.log('tidyWrapper', {html, setup, filtered});
    return filtered;
  }

  function tidy(html: string, {
    debug = DEV_MODE || PREVIEW_MODE,
    presetName = 'default',
    excludedTags = [
      'meta, script', 'link', 'frameset',
      'style', 'iframe', 'embed', 'object', 'noscript',
      'form', 'input', 'select', 'textarea'
    ],
    convertLineBrakes = false,
    allowedTags = [], // if not empty, all tags not listed here will be removed.
    fixBadLineBrakes = false,
    removeLastBr = false,
    rangyCompatibility = true,
    rangyMarkerSelector = ".rangySelectionBoundary",
    wildcardSelectors = [],
    allowedAttrs = [], // if not empty, all attributes not listed here will be removed.
    excludedAttrs = [],
    removeEvents = true,
    removeExternalAction = true,
    removeJsFromHrefs = true,
    textRestoreMethod = 'visible' // or "all"
  }:TypeSetup = {}, cacheId: string, setup: TypeSetup) {

    const elTemp = document.createElement('div');

    let htmlSrc: string = html || '';

    if (fixBadLineBrakes) { htmlSrc = htmlSrc.replace(rxBadLb, '<br />'); }
    if (convertLineBrakes) { htmlSrc = htmlSrc.replace(rxLb, '<br />'); }
    if (removeLastBr) { htmlSrc = htmlSrc.replace(rxLastBr, ''); }

    elTemp.innerHTML = htmlSrc;

    const elemsToRemove: HTMLTag[] = [];
    let allTag: HTMLTag[] = Array.from(
      elTemp.querySelectorAll(rangyCompatibility ? `*:not(${rangyMarkerSelector})` : '*')
    );

    if (Array.isArray(wildcardSelectors) && wildcardSelectors.length) {
      allTag = allTag.filter(el => !el.matches(wildcardSelectors.join(',')));
    }

    for (let i = 0; i < allTag.length; i++) {
      const elem = allTag[i];
      const tagName = elem.tagName.toLowerCase();

      if (excludedTags?.length && excludedTags.indexOf(tagName) > -1) {
        elemsToRemove.push(elem);
      }
      else if (allowedTags?.length && allowedTags.indexOf(tagName) === -1) {
        elemsToRemove.push(elem);
      }

      if (elem.hasAttributes()) {

        const attrs = Array.from(elem.attributes);

        for (let a = 0; a < attrs.length; a++) {
          const attr = attrs[a].name;
          if (
            // onClick...
            (removeEvents && attr.indexOf("on") === 0) ||
            // javascript:alert()...
            (removeJsFromHrefs && attr === 'href' && elem.href && elem.href.search(rxJs) > -1) ||
            // action="https://external-site.com"
            (removeExternalAction && attr === 'action' && isExternalUrl(elem.action || '')) ||
            (excludedAttrs?.length && excludedAttrs.indexOf(attr) > -1) ||
            (allowedAttrs?.length && allowedAttrs.indexOf(attr) === -1)
          ) {
            if (DEV_MODE || PREVIEW_MODE) {
              debug && konsole.warn('filterHtml: invalid attr found and will be removed', attr);
            }
            elem.removeAttribute(attr);
          }
        }
      }
    }


    if ((DEV_MODE || PREVIEW_MODE) && elemsToRemove.length) {
      debug && konsole.warn('filterHtml: invalid elems found and will be removed',
        elemsToRemove.map(el => ({el, html: el.innerHTML})),
        {presetName, setup}
      );
    }

    let l = elemsToRemove.length;
    while (l--) {
      const elemToRemove = elemsToRemove[l];
      if (!elemToRemove.parentNode) {
        konsole.log('Tidiy: skip elem has no parent node', elemToRemove);
        continue;
      }
      const text = textRestoreMethod === "visible" ? elemToRemove.innerText : elemToRemove.textContent;
      const nodesRangyMarkers = rangyCompatibility && elemToRemove.querySelectorAll(rangyMarkerSelector);

      if (nodesRangyMarkers !== false && nodesRangyMarkers.length) {
        const nodeRestorable = document.createElement('span');
        nodeRestorable.className = "resortable";

        while (elemToRemove.childNodes.length > 0) { // while has any child node
          debug && konsole.log('HELYREÁLLÍTÁS ELEM', elemToRemove.childNodes[0]);
          nodeRestorable.appendChild(elemToRemove.childNodes[0]);
        }
        if (nodeRestorable) {
          // elemToRemove.parentNode.insertBefore(nodeRestorable, elemToRemove.nextSibling);
          elemToRemove.insertAdjacentHTML('beforebegin', nodeRestorable.innerHTML);
        }
      }
      else if (text) {
        elemToRemove.parentNode.insertBefore(
          document.createTextNode(text),
          elemToRemove.nextSibling
        );
      }

      elemToRemove.parentNode.removeChild(elemToRemove);
    }

    return (tidyCache[cacheId] = elTemp.innerHTML);
  }

  return tidyWrapper;
})();


export function htmlFilterRangy(html: string) {
  const tmp = document.createElement('span');
  tmp.innerHTML = html;
  const all = Array.from(tmp.querySelectorAll('.rangySelectionBoundary'));

  while (all.length) {
    const one = all.pop();
    if (one && one.parentNode) {
      one.parentNode.removeChild(one);
    }
  }
  const final = tmp.innerHTML;
  return final;
}


export const filterPreviewHtml = (function() {
  const filterHtmlSetup = {
    presetName: 'filterPreviewHtml',
    rangyCompatibility: true,
    rangyMarkerSelector: ".rangySelectionBoundary",
    allowedTags: ['br', 'div'],
    allowedAttrs: ['class']
  };
  return (str: string, setup: TypeSetup) => {
    return str && filterHtml(
      str.replace(/&nbsp;|\u202F|\u00A0/g, ' '),
      setup ? {...filterHtmlSetup, ...setup} : filterHtmlSetup
    );
  };
})();
