export const apiUrl = typeof process !== 'undefined' && process?.env?.REACT_APP_APIURL
  ? process.env.REACT_APP_APIURL
  : 'https://hbioqrdev.digitalwave.hu/api'
;



export const keepSession = true;

// note: soap requests should to be proxied using soapProxy.js (_proxy/startSoapProxy.cmd)
export const routes = {
  // location change esetén pusztán param változás miatt nem kerül újratöltésre a page
  paramIndependent: [
    'foo-param-bar'
  ],
  isParamIndependentPath(pathname) {
    return !!this.paramIndependent.find(path => pathname.indexOf(path) > -1);
  }
};


export const toastOptions = (function() {
  const enums = {
    BLANK: "blank",
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning"
  };

  return {
    enums,
    [enums.WARNING]: {
      type: enums.BLANK,
      duration: 2000,
      // style: {

      // },
      icon: '⚠️'
    }
  };
})();

const setupPub = {
  toastOptions,
  apiUrl,
  keepSession
};

export default setupPub;
