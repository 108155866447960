const ENUM_fetchStatus = {
  initial: 'initial',
  success: 'success',
  aborted: 'aborted',
  error: 'error',
  loading: 'loading'
};


export {
  ENUM_fetchStatus
};
