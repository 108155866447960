import React, {useCallback} from "react";
import classnames from 'classnames';
import messages from '../../messages';
// import srcImgLevels from '../../assets/img/levels_sprite_no_brd.png';

const levels = ['empty', 'half', 'almostfull','full'];

const ReportFormLevels = ({baseClass, id, onLevelChange}) => {
  const classSpace = baseClass + '__levels';
  const onChange = useCallback((e) => {
    if (typeof onLevelChange === 'function') {
      onLevelChange(e, e.target.value, e.target.checked);
    }
  }, [onLevelChange]);

  return (
    <div className={classnames(classSpace, 'form-radios form-item')}>
      {levels.map((level, index) => (
        <div key={index} className={
          classnames(
            `${classSpace}__level`,
            `${classSpace}__level--${level}`,
          )
        }>
          <input id={`${id}-${level}`} type="radio" name="level" value={level} onChange={onChange} />
          <label htmlFor={`${id}-${level}`}>
            <span className={`${classSpace}__level__value`}>
              {messages.get(`ReportLevel.levels.${level}.value`)}
            </span>
            <p className={`${classSpace}__level__desc`}>
              {messages.get(`ReportLevel.levels.${level}.desc`)}
            </p>
          </label>
        </div>
      ))}
    </div>
  );
};

export default ReportFormLevels;
