import React from 'react';
import messages from './messages';
import Page from './components/Page';
import classnames from 'classnames';

const classSpace = "Home";


function Home() {
  return (
    <Page
      header={{
        shl: messages.get('ReportLevel.header.shl'),
        seoTitle: messages.get('ReportLevel.header.seoTitle')
      }}
      className={classnames(classSpace)}
    >
      <div className={classnames(classSpace + '__content')}>
        <h2>Ez az oldal csak a H-Bio használt sütőolaj gyűjtő tárolóin lévő QR-kód segítségével használható.</h2>
      </div>
    </Page>
  );
}

export default Home;
