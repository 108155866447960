/*
  Simple EventEmitter
  @author: Imre Ardelean blatart@gmail.com
*/

export default (function() {

  class EventObject {
    constructor(eventName, listener, data, context, once, __emiter) {
      this.__emiter = __emiter;
      this.once = !!once;
      this.eventName = eventName;
      this.listener = listener;
      this.context = context;
      this.data = data || null;
    }

    emit(...args) {
      if (this.listener) {
        this.listener.call(this.context || this, this.eventName, ...args, this.data);
        if (this.once) {
          this.__emiter.off(this.eventName, this.listener);
        }
      }
    }

    destroy() {
      for (const prop in this) {
        if ((Object.hasOwnProperty.call(this, prop))) { delete this[prop]; }
      }
    }
  }

  class EventEmitter {

    constructor(name) {
      this.name = name || null;
      this.events = {};
    }

    on(eventName, listener, data, context, once) {
      if (typeof listener !== 'function') {
        console.error(`EventEmitter [${eventName}]: listener is not function`);
        return;
      }
      if (Array.isArray(eventName)) {
        for (let i = 0; i < eventName.length; i++) {
          this.on(eventName[i], listener, data, context, once);
        }
        return this;
      }
      if (!Array.isArray(this.events[eventName])) {
        this.events[eventName] = [];
      }
      this.events[eventName].push(
        new EventObject(eventName, listener, data, context, !!once, this)
      );
      return this;
    }

    off(eventName, listener) {
      if (typeof eventName === 'function') {
        listener = eventName;
        for (eventName in this.events) {
          if ((Object.hasOwnProperty.call(this.events, eventName))) {
            this.off(eventName, listener);
          }
        }
        return this;
      }
      if (Array.isArray(eventName)) {
        for (let i = 0; i < eventName.length; i++) {
          this.off(eventName[i], listener);
        }
        return this;
      }
      if (typeof listener !== 'function') { return; }

      if (typeof this.events[eventName] === 'object') {
        for (var i = this.events[eventName].length - 1; i >= 0; i--) {
          if (this.events[eventName][i].listener === listener) {
            this.events[eventName][i].destroy();
            this.events[eventName].splice(i, 1);
          }
        }
      }
      return this;
    }

    emit(eventName, ...args) {
      if (Array.isArray(eventName)) {
        for (let i = 0; i < eventName.length; i++) {
          this.emit(eventName[i], ...args);
        }
        return this;
      }

      if (typeof this.events[eventName] === 'object') {
        for (let i = 0; i < this.events[eventName].length; i++) {
          this.events[eventName][i].emit(...args);
        }
      }
      return this;
    }

    once(eventName, listener, data, contex) {
      return this.on(eventName, listener, data, contex, true);
    }
  }

  function test() {
    var emiter = new EventEmitter();
    function callback(...a) { console.log('TEST callback FIRED', a[2], a[1], this); }

    emiter.on('testevent', callback, {subs: 1});
    emiter.emit('testevent', 'round 1');
    emiter.emit('testevent', 'round 2');
    emiter.off(callback);
    emiter.emit('testevent', 'round 3');
    emiter.on('testevent', callback, {subs: 2, foobar: 1});
    emiter.on('testevent', callback, {subs: 3, duplicate: true});
    emiter.emit('testevent', 'round 4');
    emiter.off('testevent', callback);
    emiter.emit('testevent', 'round 5');
    emiter.off(callback);
    emiter.emit('testevent', 'round 6');
    emiter.once('onlyonce', callback, {once: 1});
    emiter.emit('onlyonce', 'onlyonce 1');
    emiter.emit('onlyonce', 'onlyonce 2');
  }

  EventEmitter.test = test;
  return EventEmitter;
})();
