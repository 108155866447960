import React from 'react';
import './LoadingBounce.scss';

const LoadingBounce = () => (
  <div className="loading-spinner" aria-label="Betöltés">
    <div className="londing-spinner__bounce1"></div>
    <div className="londing-spinner__bounce2"></div>
  </div>
);
export default LoadingBounce;
