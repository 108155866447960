
export const getDeepProp = (obj: {[key: string]: any}, prop: string) => {
  const props = prop.split('.');
  let p: string;
  let found = obj;
  // date.dateformat
  while (props.length) {
    p = props.shift() as string;
    found = found[p];

    if (props.length) {
      if (typeof found !== 'object' || found[p] === null) {
        return void 0;
      }
    }
    else {
      return found;
    }
  }
};


export const setDeepProp = (obj: {[key: string]: any}, deepProp: string, value: any) => {
  const props = deepProp.split('.');
  let p: string;
  let found = obj

  // date.dateformat
  while (props.length) {
    p = props.shift() as string;

    if (props.length) {
      found = found[p];
    }
    else if (found && typeof found === "object") {
      found[p] = value;
    }
  }
  return obj;
};



export const callDeepProp = (
  {obj, deepProp, args, defValue, defArgs = []}: {obj: Object, deepProp: string, args: [], defValue: any, defArgs: []}
) => {
  let val: any;
  const _args = Array.isArray(args) ? args : defArgs;

  if (typeof obj === "object" && obj) {
    val = getDeepProp(obj, deepProp);

    val = typeof val === "function"
      ? val.apply(obj, _args)
      : val
    ;
  }
  const r = typeof val === "undefined"
    ? typeof defValue === "function" ? defValue.apply(obj, _args) : defValue
    : typeof val === "function" ? val.apply(obj, _args) : val
  ;
  // console.warn(deepProp, r)
  return r;
};
