import api from "../../../api/api.instance";
import { PREVIEW_MODE } from "../../../controller/devel";
// import {helperData} from './helper';


export const apiFetchQrData = ({code, dispatch, isMounted}) => {
  dispatch({
    type: 'loading',
    payload: { name: 'getqrdata', loading: true }
  });

  return api.getEndpointByAlias('qrcodes-getqrdata').fetch({
    requestData: {
      params: { qrcode: code }
    }
  }, (status, data) => api.fetchResponseHelper({
    status,
    data,
    isMounted: () => isMounted(),
    onAny: (succeeded) => {
      dispatch({
        type: 'loading',
        payload: { name: 'getqrdata', loading: false }
      });
    },
    onSuccess: () => {
      dispatch({
        type: 'qrData',
        payload: data.data
      });
    },
    onErrorMsg: (msg) => {
      dispatch({
        type: 'apiError',
        payload: msg
      });
    }
  }), false);
}


export const apiFetchReportLevel = ({pointid, level, message, image, dispatch, isMounted}) => {
  dispatch({
    type: 'loading',
    payload: { name: 'reportLevel', loading: true }
  });
  const formData = [];

  if (image) {
    formData.push(
      ['image', image.blob, `${image.filename || 'image'}_${Date.now()}`]
    );
  }

  // if (formData.length === 0) {
  //   const {file1pxPng} = helperData;
  //   formData.push(
  //     ['image', file1pxPng.blob, 'empty.jpg']
  //   );
  // }

  return api.getEndpointByAlias('qrcodes-reportlevel').fetch({
    formData,
    requestData: {
      params: {
        pointid,
        level,
        note: message
      }
    }
  }, (status, data) => api.fetchResponseHelper({
    status,
    data,
    isMounted: () => isMounted(),
    onAny: (succeeded) => {
      dispatch({
        type: 'loading',
        payload: { name: 'reportLevel', loading: false }
      });
    },
    onSuccess: () => {
      dispatch({
        type: 'formSuccess',
        payload: true
      });
    },
    onErrorMsg: (msg) => {
      dispatch({
        type: 'formError',
        payload: msg
      });
    }
  }), false);
}






export const apiFetchReportLog = ({pointid, level, isMounted}) => {

  return api.getEndpointByAlias('qrcodes-reportlog').fetch({
    requestData: {
      params: {
        "pointid": pointid,
        "action": 'select',
        "prop": 'level',
        "value": level,
        "timestamp": Date.now(),
      }
    }
  }, (status, data) => api.fetchResponseHelper({
    status,
    data,
    isMounted: () => isMounted(),
    onAny: (succeeded) => {

    },
    onSuccess: () => {

    },
    onErrorMsg: (msg) => {
      PREVIEW_MODE && console.error(msg);
    }
  }), false);
}