import { useRef, useEffect, DependencyList, useCallback, EffectCallback } from 'react';
// import { NavigateFunction, useLocation, useNavigate, useParams, Location } from "react-router";

export function useIsJustMounted() {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
}
//  const isMount = useIsMount();

export function useIsMounted() {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return useCallback(() => isMounted.current, []);
}

export function useDidUpdateEffect(
  effect: EffectCallback,
  deps?: DependencyList
) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    }
    else {
      return effect();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}



// export interface RoutedProps<Params = URLSearchParams, State = Location > {
//     location: State;
//     navigate: NavigateFunction;
//     params: Params;
// }


// export function withRouter<P extends RoutedProps>(Child: React.ComponentClass<P>) {
//   return (props: Omit<P, keyof RoutedProps>) => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const params = useParams();
//     return <Child { ...props as P } navigate={ navigate } location={ location } params={ params }/>;
//   };
// }

export default useIsMounted;
