import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import messages from '../../messages';
import './ReportThankyou.scss';
import Page from '../../components/Page';
import check_circle from '../../assets/img/check_circle.svg';
import {
  // useParams,
  useNavigate,
  useLocation
} from "react-router-dom";

const classSpace = "ReportThankyou";

const TimeOutRedirect = () => {
  const location = useLocation();
  const [time, setTime] = useState(4);
  useEffect(() => {
    if (!location.state && !location?.state?.succeeded) {
      return () => timer && clearTimeout(timer);
    }

    const timer = setTimeout(() => {
      setTime(time - 1);
    }, 1000);
    if (time === 0) {
      clearTimeout(timer);
      window.location.href = "https://www.h-bio.hu/";
    }
    return () => timer && clearTimeout(timer);
  }, [location.state, time]);
  return null;
};


const ReportThankyou = React.memo(function () {
  // let { code } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state && !location?.state?.succeeded) {
      navigate("/", {
        replace: true,
        state: {
          redirected: true
        }
      });
    }
  }, [location, navigate]);


  return (
    <Page
      className={classnames(classSpace)}
    >
      <TimeOutRedirect />
      <div className={classnames(classSpace + '__content')}>
        <img
          className={classnames(classSpace + '__check')}
          src={check_circle} alt={messages.get('ReportThankyou.checkAlt')}
        />
        <h1 className={classnames(classSpace + '__title')}>
          {messages.get('ReportThankyou.title')}
        </h1>
      </div>
    </Page>
  );
});

export default ReportThankyou;