import React from 'react';
import PropTypes from 'prop-types';
import Header, {propTypeHeader} from './Header';
import classnames from 'classnames';
import "./Page.scss";

function Page({header = {}, className, children}) {
  return (
    <div className={classnames("page-wrapper", className)}>
      <Header {...header} />
      <main className="page-content">
        <div className="page-width">
          {children}
        </div>
      </main>
    </div>
  );
}


Page.propTypes = {
  header: PropTypes.shape(propTypeHeader),
  className: PropTypes.string,
  children: PropTypes.node
};

export default Page;