import Api from './Api';
import {apiUrl} from '../setup.js';

const api = new Api({
  id: 'ci-api',
  sessIdPropName: 'sessionid',
  sessIsAuthName: 'session_auth',
  // sessionInitializer: 'user-isloggedin',
  tokens: {
    apiurl: apiUrl
  },
  endpointList: [ // defaults
    [
      '{apiurl}',
      {
        alias: 'qrcodes-getqrdata',
        flags: ['private'],
        defaultRequestData: {
          method: 'getqrdata',
          model: "qrcodes"
        },
        requiredRequestData: {
          params: {
            "qrcode": {type: "string" }
          }
        },
        publicPath: null
      }
    ],
    [
      '{apiurl}',
      {
        alias: 'qrcodes-reportlog',
        flags: ['private'],
        defaultRequestData: {
          method: 'setrecord',
          model: "qrcodes"
        },
        requiredRequestData: {
          params: {
            "pointid": {type: "string" },
            "action": {type: "string" },
            "prop": {type: "string" },
            "value": {type: "string", maxLenth: 255 },
            "timestamp": {type: "number" },
          }
        },
        publicPath: null
      }
    ],
    [
      '{apiurl}',
      {
        alias: 'qrcodes-reportlevel',
        multipartFormData: true,
        flags: ['private'],
        defaultRequestData: {
          method: 'levelrecord',
          model: "qrcodes"
        },
        requiredRequestData: {
          params: {
            "pointid": {type: "string" },
            "level": {type: "string" },
            "note": {type: "string", maxLenth: 255 }
          }
        },
        publicPath: null
      }
    ]
  ]
});


export default api;
