import React, {useCallback, useRef} from "react";
import classnames from 'classnames';
import ReportFormLevels from "./ReportFormLevels";
import messages from '../../messages';
import ContentParagraph from '../../components/contentParagraph';

const ReportForm = ({baseClass, onFormSubmit, onChange, formError, eventData}) => {
  const classSpace = baseClass + '__form';
  const refForm = useRef();

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(refForm.current));
    if (typeof onFormSubmit === 'function') {
      onFormSubmit(e, formData);
    }
  } , [onFormSubmit]);

  const onLevelChange = useCallback((e, level, checked) => {
    if (typeof onChange === 'function') {
      onChange(e, eventData, 'level', level, checked);
    }
  }, [onChange, eventData]);

  const onMessageChnage = useCallback((e) => {
    if (typeof onChange === 'function') {
      onChange(e, eventData, 'message', e.target.value);
    }
  }, [eventData, onChange]);


  return (
    <form
      ref={refForm}
      onSubmit={onSubmit}
      className={classnames(classSpace)}>

      <h2 className={classnames(classSpace + '__question')}>
        {messages.get('ReportLevel.formCaption')}
      </h2>

      <ReportFormLevels
        id="container-levels"
        onLevelChange={onLevelChange}
        baseClass={classSpace}
      />

      <div className={classnames(classSpace + '__message', 'form-item')}>
        <textarea
          maxLength="254"
          rows="1"
          name="message"
          onChange={onMessageChnage}
          aria-label={messages.get('ReportLevel.messageLabel')}
          placeholder={messages.get('ReportLevel.messageLabel')}
        />
      </div>
      {
        formError && (
          <ContentParagraph data={{theme: 'alert-slim'}}>{formError}</ContentParagraph>
        )
      }
      <div className={classnames(classSpace + '__submit', 'btns')}>
        <button disabled={formError} type="submit" className={'btn btn-primary'}>
          {messages.get('ReportLevel.submit')}
        </button>
      </div>
    </form>
  );
};

export default ReportForm;
