import React from 'react';
import 'normalize.css/normalize.css'
import './App.scss';
import {
  Routes,
  Route,
} from "react-router-dom";
import ReportLevel from './pages/ReportLevel';
import ReportThankyou from './pages/ReportThankyou';
import Home from './Home.js';

function App() {
  return (
    <Routes>
      <Route path="/:code" element={<ReportLevel />} />
      <Route path="/qr/:code" element={<ReportLevel />} />
      <Route path="/client/qr/:code" element={<ReportLevel />} />
      <Route path="/thanks" element={<ReportThankyou />} />
      <Route path="/client/thanks" element={<ReportThankyou />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
