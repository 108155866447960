import { konsole } from "./util";

const getGlobal = () => {
  return (typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : {});
};


interface TypeStorage {
  clear(): void;
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
  __store__?: Record<string, unknown>;
}

export class BrowserStore {
  storage: TypeStorage;

  constructor(type: 'local' | 'session' = "local") {
    // @ts-ignore
    this.storage = getGlobal()[type + 'Storage'] || {
      __store__: {},
      clear() { this.__store__ = {}; },
      getItem(key) {
        // @ts-ignore
        return typeof this.__store__[key] === "undefined"
          ? null
          // @ts-ignore
          : this.__store__[key]
        ;
      },
      setItem(key, value) {
        // @ts-ignore
        this.__store__[key] = value;
      },
      removeItem(key) {
        // @ts-ignore
        delete this.__store__[key];
      }
    };
  }

  get(prop: string, defaultValue: unknown, decodeJSON = false) {
    const s = this.storage.getItem(prop);
    let value = defaultValue;

    if (s) {
      if (decodeJSON) {
        try {
          value = JSON.parse(s);
        }
        catch (error) {
          konsole.error(`Localstorage item "${prop}" has unexpected json value`);
        }
      }
      else {
        value = s;
      }
    }
    return (value === "undefined" || value === null || typeof value === "undefined")
      ? (typeof defaultValue !== "undefined" ? defaultValue : null)
      : value;
  }

  set(prop: string, value: (string & Record<string, unknown>), asJSON = false) {
    if (value === null || typeof value === "undefined") {
      this.storage.removeItem(prop);
      return;
    }
    this.storage.setItem(prop,
      asJSON && value && typeof value === "object"
        ? JSON.stringify(value)
        : value
    );

    return value;
  }

  remove(prop: string) {
    this.storage.removeItem(prop);
  }

  clear() {
    this.storage.clear();
  }
}



export const locStore = new BrowserStore('local');
export const sesStore = new BrowserStore('session');

export default BrowserStore;
