import React, {useState} from 'react';
import reactStringReplace from "react-string-replace";
import Hyperlink from '../components/hyperlink';
import "./jsxFormatter.scss";



// Szövegformázás markup jelölőkkel:

// A szöveg részt körül kell ölelni a kívánt stílust jelölő karakter hármassal.
// Pl. Ez a szöveg ***félkövér***, ez ///dőlt///, az pedig ---apróbetűs---.

// Markup jelölők:

// Félkövér:     ***bold***
// Aláhúzott:    ___underline___
// Kisbetű :     ---smalltext---
// Nagybetű:     +++bigtext+++
// Felső index:  ^^^superindex^^^
// Alsó index:   ˇˇˇsubindexˇˇˇ
// Áthúzott:     ~~~strike~~~
// Dőlt betű     ///italic///
// Kiemelt:      !!!mark!!!

// Fontos: Több stílus egymásba ágyazása nem támogatott.


const rxAnyToken = /\*\*\*|---|÷÷÷|___|\^\^\^|˘˘˘|!!!|\/\/\/|~~~|[\n\r]|\[\/link\]/g;
// [link:/URL]TEXT[/link] || [link-blank:/URL]TEXT[/link]
const rxLink = /\[link(?:-)?([^\]]+)?:([^\]]+)\]([^[]+)\[\/link\]/i; // mathes pat
const rxLinkPre = /(\[link[^:]?[^[]+\[\/link\])/i; // matches the full token, compatible with reactStringReplace

export const cleanFormatterMarkups = s => s.replace(rxAnyToken, '');

export const jsxFormatter = (() => {
  const tags = [
    {
      token: '***',
      tag: 'b',
      rx: null,
      _rx: /\*\*\*(.*?)\*\*\*/gm
    },
    { token: '÷÷÷', tag: 'em', rx: null },
    { token: '///', tag: 'i', rx: null },
    { token: '___', tag: 'u', rx: null },
    { token: '~~~', tag: 's', rx: null },
    { token: '+++', tag: 'span', className: 'fs-big', rx: null },
    { token: '---', tag: 'small', rx: null },
    { token: '^^^', tag: 'sup', rx: null },
    { token: 'ˇˇˇ', tag: 'sub', rx: null },
    { token: '!!!', tag: 'mark', rx: null },
    { token: '\n', tag: 'br', rx: null, mono: true }
  ];
  const rxEscNeeded = /[*+^/?|$[\](){}.\\]/;
  const hocEsc = c => '\\' + c;

  // create regexes
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i];
    const escToken = tag.token.search(rxEscNeeded) > -1
      ? tag.token.split('').map(hocEsc).join('')
      : tag.token
    ;
    tag.rx = new RegExp((
      tag.mono
        ? `(${escToken})`
        : `${escToken}(.*?)${escToken}`
    ), 'gm');
  }

  return (str) => {
    let r = str || '';
    if (str.search(rxAnyToken) === -1) {
      return r; // no token found, return original string
    }
    let u = 0;
    r = reactStringReplace(r, rxLinkPre, (match, x) => {

      // eslint-disable-next-line no-unused-vars
      const [_full, target, href, text] = match.match(rxLink) || [];
      // console.warn({r, match, x, target, href, text});

      if (!href || !text) return r;

      const attrs = {key: `jsxf_link_${u++}_${x}`, href, target};
      return <Hyperlink {...attrs}>{text}</Hyperlink>;
    });

    for (let i = 0; i < tags.length; i++) {
      const {tag: Tag, rx, className = null, mono} = tags[i];
      // eslint-disable-next-line no-loop-func
      r = reactStringReplace(r, rx, (match, x) => {
        const attrs = {key: `jsxf_${Tag}_${u++}_${i}_${x}`, className};
        return mono ? <Tag {...attrs} /> : <Tag {...attrs}>{match}</Tag>;
      });
    }
    return r;
  };
})();



// eslint-disable-next-line react/prop-types
export const JsxFormatterTester = ({defaultText}) => {
  const [markupStr, setMarkupStr] = useState(defaultText || [
    "***bold***",
    "___underline___",
    "simpletext",
    "---smalltext---",
    "+++bigtext+++",
    "^^^super^^^",
    "ˇˇˇsubˇˇˇ",
    "~~~strike~~~",
    "///italic///",
    "!!!mark!!!",
    "Paragraph level-1: ***Lorem ipsum*** +++dolor+++ sit amet consectetur, ***adipisicing*** elit. Laborum, !!!beatae minima!!! blanditiis quis, facere architecto natus deserunt nobis quos ~~~necessitatibus quae nemo~~~ sequi pariatur ///asperiores enim quasi ipsa temporibus adipisci///.",
    "***NESTING +++NOT+++ SUPPORTED***"
  ].join('\n'));

  return (
    <div className="formatter-test">
      <h1>TTS tester</h1>
      <div className="form-item">
        <label>Input: </label>
        <textarea
          name="markuptest"
          defaultValue={markupStr}
          onInput={(e) => setMarkupStr(e.target.value) }
        />
      </div>
      <div className="result">
        <label>Result: </label>
        <div>{jsxFormatter(markupStr)}</div>
      </div>
    </div>
  );
};

export default jsxFormatter;
