const noop = () => {};

const getGlobalThis = () => {
  // eslint-disable-next-line no-undef
  if (typeof globalThis !== 'undefined') return globalThis;
  // eslint-disable-next-line no-undef
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  if (typeof this !== 'undefined') return this;
  return {};
};

const getWinWidth = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0;
  }
  return 0;
};

const glindow = getGlobalThis();


const ls = glindow.localStorage || {
  key: i => null,
  getItem: () => null,
  setItem: noop,
  removeItem: noop,
  clear: noop
};


let initialized = false;
const pub = {
  appPub: null,
  ls,
  wl: glindow,
  glindow
};


const globalsPub = {
  init({appPub, history, location}) {
    if (initialized) { return; }
    initialized = true;
    pub.appPub = appPub;
    pub.history = history;
    pub.location = location;
  },

  get(prop) {
    return pub[prop];
  }
};

export default globalsPub;

export {
  getWinWidth,
  glindow
};
