import React from 'react';
import PropTypes from "prop-types"
import classnames from 'classnames';
// import logo from '../assets/img/h-bio-logo.png'
import logo from '../assets/img/h-bio-logo-b.png'
import poi from '../assets/img/poi.svg'

import messages from '../messages';
import './Header.scss';

const baseClass = "hbio-header";


const Header = ({address, seoTitle, shl = null, className}) => {
  return (
    <header className={classnames(baseClass, className)}>
      <div className={classnames(baseClass + "__top page-width")}>
        {seoTitle ? (
          <h1 className={classnames(baseClass + "__seotitle", 'offscreen')}>
            {seoTitle}
          </h1>
        ) : null}

        <a
          className={classnames(baseClass + "__logo")}
          href="https://www.h-bio.hu/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={logo}
            alt={
              messages.get('components.Header.logoAlt')
            } />
        </a>

        {address ? (
          <address className={classnames(`${baseClass}__site`)}>
            <img src={poi} alt={messages.get('components.Header.poiAlt')} />
            <span title={address}>{address}</span>
          </address>
        ) : null}
      </div>

      <div className={classnames(baseClass + "__bottom page-width")}>
        {shl ? (
          <h2 className={classnames(baseClass + "__shl")}>
            {shl}
          </h2>
        ) : null}
      </div>
    </header>
  );
};



export const propTypeHeader = {
  address: PropTypes.node,
  seoTitle: PropTypes.node,
  shl: PropTypes.node,
  className: PropTypes.string
};

Header.prototype = propTypeHeader;

export default Header;
