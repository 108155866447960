import { getDeepProp } from './lib/objects/deepOps';
import jsxFormatter from './lib/jsxFormatter';

const cache = {};

export const messages = {
  Page404: {
    headline: "+++404+++/// | ///---Az oldal nem található.---",
    action: 'Vissza a főoldalra'
  },
  ReportThankyou: {
    checkAlt: 'Sikeres beküldés',
    title: `Köszönjük,
    hogy hozzájárultál
    a jövő jobbá tételéhez!`
  },
  ReportLevel: {
    header: {
      seoTitle: 'H-Bio - használt sütőolaj újrahasznisítás - tároló szint visszajelzés',
      shl: 'Újrahasznosítást segítő közösségi szintmérés'
    },
    formCaption: 'Mennyire van megtelve a konténer?',
    levelLabel: 'A konténer',
    messageLabel: 'Megjegyzés...',
    formError: {
      noLevel: 'Kérlek válassz egy telítettségi szintet!'
    },
    levels: {
      empty: {
        value: 'ÜRESNEK TŰNIK',
        desc: 'A bedobónyíláson keresztül nem látszanak flakonok.'
      },
      half: {
        value: 'FÉLIG MEGTELT',
        desc: 'Még bőven van benne hely, hogy tele legyen.'
      },
      almostfull: {
        value: 'MAJDNEM TELE',
        desc: 'Alig van hely további flakonoknak.'
      },
      full: {
        value: 'TELJESEN MEGTELT',
        desc: 'Több flakont már nem lehet bedobni.'
      }
    },
    submit: 'Elküld',
  },
  components: {
    Header: {
      logoAlt: 'H-Bio logó',
      poiAlt: 'Helyszín'
    },
    LayoutUnavailable: {
      btnToFront: 'Főoldal'
    },
    Preloader: {
      error: 'Hiba:',
      loading: 'Betöltés:'
    }
  },
  inputScheme: {
    termsUnchecked: 'Az ÁSZF elfogadása kötelező a továbblépéshez.',
    tooShort: 'A megadott érték túl rövid',
    tooLong: 'A megadott érték túl hosszú',
    requiredField: 'Kötelezően mező.'
  },

  getStrict(prop, replacements, clearCache) {
    return this.get(prop, replacements, clearCache, true);
  },

  get(prop, replacements, clearCache, strict) {
    const hasReplacements = replacements && typeof replacements === "object";
    const cacheId = `${prop}--${hasReplacements ? JSON.stringify(replacements) : 'raw'}`;

    if (!clearCache && cache[cacheId]) {
      return cache[cacheId];
    }

    let msg = getDeepProp(messages, prop);
    if (msg && typeof msg === 'string' && hasReplacements) {
      for (const prop in replacements) {
        if (Object.hasOwnProperty.call(replacements, prop)) {
          const value = replacements[prop];
          const token = new RegExp('\\$\\[' + prop + '\\]', 'g');
          msg = msg.replace(token, value);
        }
      }
    }

    const value = typeof msg === "string" ? jsxFormatter(msg) : msg;
    const valid = strict || typeof value === "string" || Array.isArray(value);
    if (!valid) {
      console.error('invalid message request: ' + prop, {
        valid, value, strict, prop, clearCache, replacements
      });
    }
    return !valid
      ? prop
      : (cache[cacheId] = value)
    ;
  }
};

export default messages;
