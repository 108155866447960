

export const initialtState = {
  ready: false,
  loading: false,
  formError: false,
  qrData: null,
  formSuccess: false,
  apiError: null,
  loaders: {}
};


export default function reducer(state, {type, payload}) {
  switch (type) {
    case 'loading': {
      const {name, loading} = payload;
      const loaders = {...state.loaders};

      if (loading) {
        loaders[name] = true;
      }
      else if (state.loaders[name]) {
        delete loaders[name];
      }

      return {
        ...state,
        loaders,
        loading: Object.keys(loaders).length > 0
      };
    }
    case 'qrData': {
      return {
        ...state,
        qrData: payload
      };
    }
    case 'formSuccess': {
      return {
        ...state,
        formSuccess: payload
      };
    }
    case 'formError': {
      return {
        ...state,
        formError: payload
      };
    }
    case 'apiError': {
      return {
        ...state,
        formSuccess: false,
        apiError: payload
      };
    }
    case 'extend': {
      return {
        ...state,
        ...payload
      };
    }
    default:
      throw new Error('Unhandled action.type ' + type);
  }
}
