import {DEV_MODE} from "../../controller/devel";
import { PREVIEW_MODE } from "../../controller/devel";

const normalizeTag = (() => {
  const rxContainers = /blockquote|fieldset|section|address|article|dialog|figure|footer|aside|header|hgroup|main|form|div|nav|pre/ig;
  const rxClean = /^\d+|[^a-z1-6]+|(null|undefined|NaN)/ig;
  return (tag: string, _caller: string, mode: 'container-elements' | 'any' = 'any') => {
    let clean = (tag + '').replace(rxClean, '');
    if (mode === "container-elements" && clean.search(rxContainers) === -1) {
      (DEV_MODE || PREVIEW_MODE) && console.error(
        'normalizeTag: invalid container-element', tag, _caller ? `found in ${_caller} (replaced with div)` : ''
      );
      clean = 'div';
    }

    if (!clean) {
      (DEV_MODE || PREVIEW_MODE) && console.error(
        'normalizeTag: invalid html tagName', tag, _caller ? `found in ${_caller}. (replaced with div)` : ''
      );
    }
    return clean || 'mark';
  };
})();


export default normalizeTag;
