import React, { useEffect, useCallback, useReducer } from 'react';
import classnames from 'classnames';
import messages from '../../messages';
import './ReportLevel.scss';
import ReportForm from './ReportForm';
import Page from '../../components/Page';
import { useNavigate } from "react-router-dom";
import reducer, {initialtState} from './data/reducer';
import ContentParagraph from '../../components/contentParagraph';
import LoadingBounce from '../../components/LoadingBounce';
import {formatAddress} from './data/helper';
import {apiFetchQrData, apiFetchReportLevel, apiFetchReportLog} from './data/apiCalls';

import {
  useParams,
} from "react-router-dom";
import {useIsMounted} from '../../hooks';



const classSpace = "ReportLevel";

const ReportLevel = React.memo(function () {
  let { code } = useParams();
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, {
    ...initialtState
  });

  const onFormSubmit = useCallback((e, formData) => {
    e.preventDefault();
    const { message, level } = formData;
    const {pointid} = state.qrData || {};

    if (!level) {
      dispatch({
        type: 'formError',
        payload: messages.get('ReportLevel.formError.noLevel')
      });
      return;
    }

    let msg = message;
    if (message.length > 254) {
      msg = message.substring(0, 254);
    }

    apiFetchReportLevel({pointid, level, message: msg, image: null, dispatch, isMounted});

  } , [isMounted, state.qrData]);

  const onChange = useCallback((e, eventData, name, value, checked) => {
    if (name === 'level') {
      const {pointid} = state.qrData || {};
      apiFetchReportLog({pointid, level: checked ? value : null, isMounted});
    }
    dispatch({
      type: 'formError',
      payload: false
    });
  } , [isMounted, state.qrData]);


  useEffect(() => {
    apiFetchQrData({code, dispatch, isMounted});
  }, [code, isMounted]);

  useEffect(() => {
    if (state.formSuccess) {
      navigate("/thanks", {
        // replace: true
        state: {
          succeeded: true
        }
      });
    }
  } , [navigate, state.formSuccess]);

  return (
    <Page
      header={{
        shl: messages.get('ReportLevel.header.shl'),
        address: state.qrData
          ? formatAddress(state.qrData.pointcity, state.qrData.pointaddress)
          : null,
        seoTitle: messages.get('ReportLevel.header.seoTitle'),
      }}
      className={classnames(classSpace)}
    >
      {state.loading && <LoadingBounce /> }

      {!state.loading && (
        state.apiError ? (
          <ContentParagraph data={{theme: 'alert'}}>{state.apiError}</ContentParagraph>
        ) : (
          <ReportForm
            eventData={state.qrData}
            baseClass={classSpace}
            onFormSubmit={onFormSubmit}
            onChange={onChange}
            formError={state.formError}
          />
        )
      )}
    </Page>
  );
});

export default ReportLevel;