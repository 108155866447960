export const formatAddress = (city, address) => {
  return `${city ? city + ', ' : ''}${address || ''}`;
};

export const helperData = {
  file1pxPng: null
};


// // TODO: szerveroldali hiba javításáig 1px kép
// const get1pxPng = (function () {
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');
//   canvas.width = 1;
//   canvas.height = 1;
//   let fileUrl = null;

//   const imgSetup = {
//     mimeType: "image/png",
//     quality: 0.9
//   };

//   return function createPixel(fileName = '1px.png') {
//     ctx.fillStyle = 'rgba(0,0,0,1)';
//     ctx.fillRect(0,0,1,1);

//     return new Promise((resolve, reject) => {
//       // toDataURL
//       canvas.toBlob((blob) => {
//         if (!blob) {
//           // reject(new Error('Canvas is empty'));
//           console.error("Canvas is empty");
//           return;
//         }
//         blob.name = fileName;
//         if (fileUrl) {
//           window.URL.revokeObjectURL(fileUrl);
//         }
//         fileUrl = window.URL.createObjectURL(blob);
//         const file = new File([blob], fileName, { type: imgSetup.mimeType });

//         resolve({fileUrl, blob, file});

//       }, imgSetup.mimeType, imgSetup.quality);
//     });

//   }
// })();

// const make1pxPng = async () => {
//   helperData.file1pxPng = await get1pxPng('holder.png');;
// };

// make1pxPng();
